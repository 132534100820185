import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CategoriesList from "./knowledgebase-category-list"
import PostList from "./knowledgebase-post-list"
import Grid from "./knowledgebase-post-grid"
import HeroKnowledgeBase from "../../components/pages/shared/hero/knowledge-base"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Breadcrumb from '../../components/controls/breadcrumbs';
// import DiscountBanner from "../../../src/knowledgebase/components/banner"

const KnowledgebaseCategory = (props) => {
  const { edges } = props.data.allMarkdownRemark
  const category = props.pageContext.pathSlug
  const [categoryName, slug] = category.split('|')
  const categotyDescription = new Map([
    ['access-rights-and-roles', 'Все материалы из категории «Права доступа и роли». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['analytics-and-reports', 'Все материалы из категории «Аналитика и отчеты». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['attendance-and-attendance-statuses', 'Все материалы из категории «Посещения и статусы посещений». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['cashbox', 'Все материалы из категории «Онлайн-касса». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['employees', 'Все материалы из категории «Сотрудники». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['entries', 'Все материалы из категории «Записи и Онлайн-запись». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['finance', 'Все материалы из категории «Финансы». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['franchise-and-premises', 'Все материалы из категории «Филиалы, помещения». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['groups', 'Все материалы из категории «Группы». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['main-screen', 'Все материалы из категории «Главный экран». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['notifications', 'Все материалы из категории «Уведомления». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['rates-and-payment', 'Все материалы из категории «Тарифы и оплата». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['salary', 'Все материалы из категории «Зарплата». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['sales-funnel', 'Все материалы из категории «Воронка продаж». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['schedule-and-lessons', 'Все материалы из категории «Расписание и Занятия». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['settings', 'Все материалы из категории «Раздел Настройки». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['sms-mailings', 'Все материалы из категории «СМС-рассылки». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['students-accounts', 'Все материалы из категории «Личные кабинеты». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['students-balance', 'Все материалы из категории «Баланс клиента». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['students', 'Все материалы из категории «Клиенты». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['tasks', 'Все материалы из категории «Задачи». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['teachers', 'Все материалы из категории «Сотрудники». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['telegram-integration', 'Все материалы из категории «Интеграция с Telegram». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['telephony', 'Все материалы из категории «Телефония». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['tickets', 'Все материалы из категории «Абонементы». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['whatsApp-integration', 'Все материалы из категории «Интеграция с WhatsApp». Используйте базу знаний для поиска инструкций и обучающих статей по системе Параплан CRM.'],
    ['users', 'Пользовательские доступы в CRM системе Параплан. Подробные инструкции и ответы на вопросы вы всегда сможете найти в базе знаний.'],
    ['franchise-and-rooms', 'Филиалы и помещения в CRM системе Параплан. Подробные инструкции и ответы на вопросы вы всегда сможете найти в базе знаний.']
  ]);
  const crumbs = [
    { name: 'Главная', url: '/' },
    { name: 'База знаний', url: '/knowledgebase' },
    { name: categoryName, url: '' }
  ];
  return (
    <Layout page={slug.substr(1)} headerColor={"#319DED"}>
      <HeroKnowledgeBase name={"knowledgebase"} />
      <Breadcrumb crumbs={crumbs} arrowcolor={"blue"} />
      {/* <DiscountBanner /> */}
      <SEO title={'Раздел «' + categoryName.trim() + '»'} description={categotyDescription.get(slug.slice(1))} />
      <Grid>
        <CategoriesList currentCategory={category} />
        <PostList posts={edges} headline={categoryName} />
      </Grid>
      <ContactFormComponent color={"#F9F9FA"} />
    </Layout>
  )
}

export const query = graphql`
  query KnowledgebaseCategoryQuery($pathSlug: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { 
        fileAbsolutePath: {regex: "/src/knowledgebase/"}
        frontmatter: { 
          category: {eq: $pathSlug} 
          path: {ne: null}
        }
      }
    ) {
      edges {
        node {
          frontmatter{
            cover {
              childImageSharp {
                sm: fluid(maxWidth: 470) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                mob: fluid(maxWidth: 835) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            lead {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            excerpt
            path
            title
            category
            mark
          }
          timeToRead
        }
      }
    }
    allPosts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/src/knowledgebase/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            cover {
              childImageSharp {
                sm: fluid(maxWidth: 470) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                mob: fluid(maxWidth: 835) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            lead {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            excerpt
            path
            title
            category
            mark
          }
          timeToRead
        }
      }
    }
  }
`

export default KnowledgebaseCategory